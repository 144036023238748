<template>

  <div class="flex flex-col">
    <div class="text-il-dark font-bold uppercase text-xs mt-4">
      Item Label
    </div>
    <textarea
      ref="itemRef"
      class="text-lg outline-none border border-gray-400 focus:border-il-purple px-2 py-1 transition-all duration-200 ease-in-out"
      rows='1'
      data-min-rows='1'
      data-height-step='28'
      :value="itemLabel"
      @input="updateValue('itemLabel', $event.target, 28)"
    >
    </textarea>

    <div class="text-il-dark font-bold uppercase text-xs mt-4">
      Quantity Label
    </div>
    <textarea
      ref="quantityRef"
      class="text-lg outline-none border border-gray-400 focus:border-il-purple px-2 py-1 transition-all duration-200 ease-in-out"
      rows='1'
      data-min-rows='1'
      data-height-step='28'
      :value="quantityLabel"
      @input="updateValue('quantityLabel', $event.target, 28)"
    >
    </textarea>

    <div class="text-il-dark font-bold uppercase text-xs mt-4">
      Rate Label
    </div>
    <textarea
      ref="rateRef"
      class="text-lg outline-none border border-gray-400 focus:border-il-purple px-2 py-1 transition-all duration-200 ease-in-out"
      rows='1'
      data-min-rows='1'
      data-height-step='28'
      :value="rateLabel"
      @input="updateValue('rateLabel', $event.target, 28)"
    >
    </textarea>

    <div class="text-il-dark font-bold uppercase text-xs mt-4">
      Amount Label
    </div>
    <textarea
      ref="amountRef"
      class="text-lg outline-none border border-gray-400 focus:border-il-purple px-2 py-1 transition-all duration-200 ease-in-out"
      rows='1'
      data-min-rows='1'
      data-height-step='28'
      :value="amountLabel"
      @input="updateValue('amountLabel', $event.target, 28)"
    >
    </textarea>
  </div>

</template>


<script>
  import { mapGetters, mapMutations } from 'vuex';

  export default {
    props: [
      'itemLabel',
      'quantityLabel',
      'rateLabel',
      'amountLabel'
    ],
    emits: [
      'update:itemLabel',
      'update:quantityLabel',
      'update:rateLabel',
      'update:amountLabel'
    ],
    mounted() {
      this.$nextTick(function() {
        this.autoResizeMX(this.$refs.itemRef,28);
        this.autoResizeMX(this.$refs.quantityRef,28);
        this.autoResizeMX(this.$refs.rateRef,28);
        this.autoResizeMX(this.$refs.amountRef,28);
      });
    },
    data() {
      return {

      }
    },
    methods: {
      ...mapMutations([

      ]),
      updateValue(prop,target,heightStep) {
        this.autoResizeMX(target,heightStep);
        this.$emit('update:'+prop,target.value);
      }
    },
    computed: {
      ...mapGetters([

      ]),
    }
  }
</script>