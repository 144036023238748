import store from '../store';
import axios from 'axios';
axios.defaults.withCredentials = true;

const clientUrl = store.state.apiUrl+'client/';

export default {
  createNewClient(name) {
    return axios({
      method: 'post',
      url: clientUrl + 'create',
      data: {
        name: name
      }
    });
  },
  updateDefaults(clientData) {
    return axios({
      method: 'post',
      url: clientUrl + 'update/defaults',
      data: clientData
    });
  },
  updateDetails(clientData) {
    return axios({
      method: 'post',
      url: clientUrl + 'update/details',
      data: clientData
    });
  },
  updateClientName(clientName, clientId) {
    return axios({
      method: 'post',
      url: clientUrl + 'update/name',
      data: {
        name: clientName,
        id: clientId
      }
    });
  },

  // updateClient(clientData) {
  //   return axios({
  //     method: 'post',
  //     url: clientUrl + 'update',
  //     data: clientData
  //   });
  // },
}