<template>

  <div class="flex flex-col">
    <div class="text-il-dark font-bold uppercase text-xs mt-4">
      bill to label
    </div>
    <textarea
      ref="labelRef"
      class="text-lg outline-none border border-gray-400 focus:border-il-purple px-2 py-1 transition-all duration-200 ease-in-out"
      rows='1'
      data-min-rows='1'
      data-height-step='28'
      :value="toLabel"
      @input="updateValue('toLabel', $event.target, 28)"
    >
    </textarea>

    <div class="text-il-dark font-bold uppercase text-xs mt-4">
      bill to name
    </div>
    <textarea
      ref="nameRef"
      class="text-lg outline-none border border-gray-400 focus:border-il-purple px-2 py-1 transition-all duration-200 ease-in-out"
      rows='1'
      data-min-rows='1'
      data-height-step='28'
      :value="toName"
      @input="updateValue('toName', $event.target, 28)"
    >
    </textarea>

    <div class="text-il-dark font-bold uppercase text-xs mt-4">
      bill to address
    </div>
    <textarea
      ref="addressRef"
      class="text-lg outline-none border border-gray-400 focus:border-il-purple px-2 py-1 transition-all duration-200 ease-in-out"
      rows='1'
      data-min-rows='1'
      data-height-step='28'
      :value="toAddress"
      @input="updateValue('toAddress', $event.target, 28)"
    >
    </textarea>
  </div>

</template>

<script>
  import { mapGetters, mapMutations } from 'vuex';

  export default {
    props: [
      'accentTextColor',
      'toLabel',
      'toName',
      'toAddress'
    ],
    emits: [
      'update:toLabel',
      'update:toName',
      'update:toAddress'
    ],
    mounted() {
      this.$nextTick(function() {
        this.autoResizeMX(this.$refs.labelRef,28);
        this.autoResizeMX(this.$refs.nameRef,28);
        this.autoResizeMX(this.$refs.addressRef,28);
      });
    },
    data() {
      return {

      }
    },
    methods: {
      ...mapMutations([

      ]),
      updateValue(prop,target,heightStep) {
        this.autoResizeMX(target,heightStep);
        this.$emit('update:'+prop,target.value);
      }
    },
    computed: {
      ...mapGetters([

      ])
    }
  }
</script>