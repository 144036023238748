<template>

  <div class="flex flex-col">
    <div class="text-il-dark font-bold uppercase text-xs mt-4">
      subtotal label
    </div>
    <input
      class="text-lg outline-none border border-gray-400 focus:border-il-purple px-2 py-1 transition-all duration-200 ease-in-out"
      type="text"
      :value="subtotalLabel"
      @input="updateValue('subtotalLabel', $event.target.value)"
    />

    <div class="text-il-dark font-bold uppercase text-xs mt-4">
      discount label
    </div>
    <input
      class="text-lg outline-none border border-gray-400 focus:border-il-purple px-2 py-1 transition-all duration-200 ease-in-out"
      type="text"
      :value="discountLabel"
      @input="updateValue('discountLabel', $event.target.value)"
    />

    <div class="text-il-dark font-bold uppercase text-xs mt-4">
      tax label
    </div>
    <input
      class="text-lg outline-none border border-gray-400 focus:border-il-purple px-2 py-1 transition-all duration-200 ease-in-out"
      type="text"
      :value="taxLabel"
      @input="updateValue('taxLabel', $event.target.value)"
    />

    <div class="text-il-dark font-bold uppercase text-xs mt-4">
      shipping label
    </div>
    <input
      class="text-lg outline-none border border-gray-400 focus:border-il-purple px-2 py-1 transition-all duration-200 ease-in-out"
      type="text"
      :value="shippingLabel"
      @input="updateValue('shippingLabel', $event.target.value)"
    />

    <div class="text-il-dark font-bold uppercase text-xs mt-4">
      amount paid label
    </div>
    <input
      class="text-lg outline-none border border-gray-400 focus:border-il-purple px-2 py-1 transition-all duration-200 ease-in-out"
      type="text"
      :value="amountPaidLabel"
      @input="updateValue('amountPaidLabel', $event.target.value)"
    />

    <div class="text-il-dark font-bold uppercase text-xs mt-4">
      total label
    </div>
    <input
      class="text-lg outline-none border border-gray-400 focus:border-il-purple px-2 py-1 transition-all duration-200 ease-in-out"
      type="text"
      :value="totalLabel"
      @input="updateValue('totalLabel', $event.target.value)"
    />
  </div>

</template>


<script>
  export default {
    props: [
      'subtotalLabel',
      'discountLabel',
      'taxLabel',
      'shippingLabel',
      'amountPaidLabel',
      'totalLabel'
    ],
    emits: [
      'update:subtotalLabel',
      'update:discountLabel',
      'update:taxLabel',
      'update:shippingLabel',
      'update:amountPaidLabel',
      'update:totalLabel'
    ],
    methods: {
      updateValue(prop,value) {
        this.$emit('update:'+prop,value);
      }
    }
  }
</script>