import store from '../store';
import axios from 'axios';
axios.defaults.withCredentials = true;

const quickInvoiceUrl = store.state.apiUrl+'quick-invoice/';

export default {
  createOrUpdate(
    logoString,
    logoExtension,
    invoiceUUID,
    invoiceData
  ) {
    return axios({
      method: 'post',
      url: quickInvoiceUrl + 'create-update',
      data: {
        logo: logoString,
        logo_extension: logoExtension,
        invoice_data: invoiceData,
        invoice_uuid: invoiceUUID
      },
      headers: {
        'Content-Type': 'application/json'
      }
    });
  },
  getInvoiceDetails(invoiceUUID) {
    return axios({
      method: 'post',
      url: quickInvoiceUrl + 'details',
      data: {
        'invoice_uuid': invoiceUUID,
      },
    });
  },
  downloadPDF(invoiceUUID) {
    return axios({
      method: 'post',
      url: quickInvoiceUrl + 'generate',
      data: {
        'invoice_uuid': invoiceUUID,
      },
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/pdf'
      }
    });
  },
  deleteInvoice(invoiceUUID) {
    return axios({
      method: 'post',
      url: quickInvoiceUrl + 'delete',
      data: {
        'invoice_uuid': invoiceUUID,
      }
    });
  },
}