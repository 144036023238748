<template>
    <div
      v-if="clientData"
      class="w-full m-8 bg-white rounded-lg p-4"
    >

      <div
        class="flex flex-col relative mt-8"
      >
        <div
          class="flex justify-center text-2xl text-il-dark font-bold"
          v-if="!editingClientName"
        >
          <div class="flex w-300px text-center items-center">
            <div class="flex-grow">
              {{ clientData.name }}
            </div>
            <div
              class="flex-shrink text-sm text-gray-300 hover:text-il-dark cursor-pointer"
              @click="clientNameEditToggle"
            >
              <i class="fas fa-edit"></i>
            </div>
          </div>
        </div>

        <div
          v-else
          class="flex justify-center text-2xl text-il-dark font-bold"
        >
          <div class="flex w-300px text-center items-center">
            <div class="flex-grow">
              <input
                ref="clientNewNameInput"
                type="text"
                class="rounded-l-lg border border-gray-400 focus:border-il-purple outline-none p-2 py-0 font-bold text-center"
                v-model="clientNewName"
                :readonly="detailsUpdating"
                @keyup.enter="updateClientName"
              >
            </div>

            <div
              v-if="!detailsUpdating"
              class="flex flex-shrink"
            >
              <div
                class="flex-shrink bg-il-teal-faded hover:bg-il-teal text-white text-sm p-2 transition-all duration-200 ease-in-out cursor-pointer"
                @click="updateClientName"
              >
                <i class="fas fa-check-circle"></i>
              </div>
              <div
                class="flex-shrink bg-red-300 hover:bg-red-600 text-white text-sm p-2 rounded-r-lg transition-all duration-200 ease-in-out cursor-pointer"
                @click="clientNameEditToggle"
              >
                <i class="fas fa-times-circle"></i>
              </div>
            </div>
            <div
              v-else
              class="bg-il-teal text-white p-2 px-4 text-sm rounded-r-lg"
            >
              <i class="fas fa-circle-notch fa-spin"></i>
            </div>
          </div>
        </div>

        <div class="text-center text-gray-400 uppercase text-sm mt-2">
          Use this page to manage your client invoice defaults and details
        </div>

        <div
          @click="navigateToMX('clients')"
          class="absolute flex items-center -top-8 text-il-dark-faded hover:text-il-dark duration-200 transition-all ease-in-out cursor-pointer"
        >
          <i class="fas fa-chevron-left fa-2x"></i>
          <span class="ml-4">Back to Clients</span>
        </div>
      </div>

      <div
        class="flex flex-wrap mt-8"
      >
        <div
          class="flex flex-col w-full lg:w-6/12 p-4"
        >
          <div
            class="text-white bg-il-dark text-center font-bold text-xl p-2 uppercase rounded-t-lg"
          >
            Invoice Defaults
          </div>

          <div class="p-2 shadow-xl rounded-b-lg mb-4">
            <Customisation
              v-if="clientData.currency_id"
              v-model:accentColor="clientData.accent_color"
              v-model:accentTextColor="clientData.accent_text_color"
              v-model:currencyId="clientData.currency_id"
            />

            <TitleAndPaymentInfo
              v-model:title="clientData.invoice_title"
              v-model:invoiceDateLabel="clientData.invoice_date_label"
              v-model:paymentDateLabel="clientData.payment_date_label"
              v-model:paymentTermsLabel="clientData.payment_terms_label"
              v-model:paymentTerms="clientData.payment_terms"
              v-model:paymentPeriod="clientData.payment_period"
              v-model:balanceDueLabel="clientData.balance_due_label"
              v-model:currencyLabel="clientData.currency_label"
            />

            <hr class="mt-8 border-dashed border-2 border-il-dark" />

            <To
              class="mt-4"
              v-model:toLabel="clientData.bill_to_label"
              v-model:toName="clientData.bill_to_name"
              v-model:toAddress="clientData.bill_to_address"
            />

            <hr class="mt-8 border-dashed border-2 border-il-dark" />

            <ShipTo
              class="mt-4"
              v-model:shipToLabel="clientData.ship_to_label"
              v-model:shipToName="clientData.ship_to_name"
              v-model:shipToAddress="clientData.ship_to_address"
            />

            <hr class="mt-8 border-dashed border-2 border-il-dark" />

            <Items
              class="mt-4"
              v-model:itemLabel="clientData.item_label"
              v-model:quantityLabel="clientData.quantity_label"
              v-model:rateLabel="clientData.rate_label"
              v-model:amountLabel="clientData.amount_label"
            />

            <hr class="mt-8 border-dashed border-2 border-il-dark" />

            <NotesAndTerms
              class="mt-4"
              v-model:notesLabel="clientData.notes_label"
              v-model:notesText="clientData.notes"
              v-model:termsLabel="clientData.terms_label"
              v-model:termsText="clientData.terms"
            />

            <hr class="mt-8 border-dashed border-2 border-il-dark" />

            <Totals
              class="mt-4"
              v-model:subtotalLabel="clientData.subtotal_label"
              v-model:discountLabel="clientData.discount_label"
              v-model:taxLabel="clientData.tax_label"
              v-model:shippingLabel="clientData.shipping_cost_label"
              v-model:amountPaidLabel="clientData.amount_paid_label"
              v-model:totalLabel="clientData.total_label"
            />

            <div class="flex justify-center mt-4 mb-4">
              <div
                class=" py-2 px-4 mt-2 uppercase rounded-lg text-sm cursor-pointer border border-il-dark  transition-all duration-200 ease-in-out"
                :class="detailsUpdating ? 'bg-il-dark text-white' : 'text-il-dark bg-white hover:bg-il-dark hover:text-white'"
                @click="updateDefaults"
              >
                <span
                  v-if="!detailsUpdating"
                >
                  Update Invoice Defaults
                </span>
                <span
                  v-else
                >
                  <i class="fas fa-circle-notch fa-spin text-white"></i>
                </span>
              </div>
            </div>
          </div>

        </div>

        <div
          class="flex flex-col w-full lg:w-6/12 p-4"
        >
          <div
            class="text-white bg-il-dark text-center font-bold text-xl p-2 uppercase rounded-t-lg"
          >
            Client Details <span class="mx-2">&</span> Email Defaults
          </div>

          <div class="shadow-xl rounded-b-lg p-2">
            <div class="flex flex-col">

              <div class="text-il-dark font-bold uppercase text-xs mt-4">
                phone number
              </div>
              <input
                class="text-lg outline-none border border-gray-400 focus:border-il-purple px-2 py-1 transition-all duration-200 ease-in-out"
                type="text"
                v-model="clientData.phone"
              />

              <div class="text-il-dark font-bold uppercase text-xs mt-4">
                website
              </div>
              <input
                class="text-lg outline-none border border-gray-400 focus:border-il-purple px-2 py-1 transition-all duration-200 ease-in-out"
                type="text"
                v-model="clientData.website"
              />

              <hr class="mt-8 border-dashed border-2 border-il-dark" />

              <div class="text-il-dark font-bold uppercase text-xs mt-6">
                email
              </div>
              <input
                class="text-lg outline-none border border-gray-400 focus:border-il-purple px-2 py-1 transition-all duration-200 ease-in-out"
                type="text"
                v-model="clientData.email"
              />

              <div class="text-il-dark font-bold uppercase text-xs mt-4">
                email greeting
              </div>
              <input
                class="text-lg outline-none border border-gray-400 focus:border-il-purple px-2 py-1 transition-all duration-200 ease-in-out"
                type="text"
                v-model="clientData.contact_name"
              />

              <div class="text-il-dark font-bold uppercase text-xs mt-4">
                email message
              </div>
              <textarea
                ref="emailMessageRef"
                class="text-lg outline-none border border-gray-400 focus:border-il-purple px-2 py-1 transition-all duration-200 ease-in-out"
                rows='1'
                data-min-rows='1'
                data-height-step='28'
                v-model="clientData.email_message"
                @input="autoResizeMX($event.target, 28)"
              >
              </textarea>

              <div class="text-il-dark font-bold uppercase text-xs mt-4">
                email invoice link message
              </div>
              <input
                class="text-lg outline-none border border-gray-400 focus:border-il-purple px-2 py-1 transition-all duration-200 ease-in-out"
                type="text"
                v-model="clientData.invoice_link_message"
              />

            </div>

            <div class="flex justify-center mt-4 mb-4">
              <div
                class=" py-2 px-4 mt-2 uppercase rounded-lg text-sm cursor-pointer border border-il-dark  transition-all duration-200 ease-in-out"
                :class="detailsUpdating ? 'bg-il-dark text-white' : 'text-il-dark bg-white hover:bg-il-dark hover:text-white'"
                @click="updateDetails"
              >
                <span
                  v-if="!detailsUpdating"
                >
                  Update Client Details
                </span>
                <span
                  v-else
                >
                  <i class="fas fa-circle-notch fa-spin text-white"></i>
                </span>
              </div>
            </div>
          </div>

        </div>

      </div>

    </div>
</template>

<script>
  import clientRequests from '../../helpers/clientRequests';
  import lodash from 'lodash';
  import { mapGetters, mapMutations } from 'vuex';

  import Customisation from '../../components/ClientSettings/Customisation';
  import TitleAndPaymentInfo from '../../components/ClientSettings/TitleAndPaymentInfo';
  import To from '../../components/ClientSettings/To';
  import ShipTo from '../../components/ClientSettings/ShipTo';
  import Items from '../../components/ClientSettings/Items';
  import NotesAndTerms from '../../components/ClientSettings/NotesAndTerms';
  import Totals from '../../components/ClientSettings/Totals';

  export default {
    mounted() {
      this.clientData = this.client();
      this.$nextTick(function() {
        this.autoResizeMX(this.$refs.emailMessageRef,28);
      });
    },
    data() {
      return {
        clientUUID: this.$route.params.client_uuid,
        clientData: null,
        detailsUpdating: false,
        editingClientName: false
      }
    },
    components: {
      Customisation,
      TitleAndPaymentInfo,
      To,
      ShipTo,
      Items,
      NotesAndTerms,
      Totals
    },
    methods: {
      ...mapMutations([
        'setUserCurrentCompanyX'
      ]),
      goBack() {
        this.$router.go(-1);
      },
      updateDefaults() {
        if(!this.detailsUpdating) {
          // let cloneClientData = { ...this.clientData };
          this.detailsUpdating = true;
          clientRequests.updateDefaults(this.clientData)
          .then((response) => {
            this.detailsUpdating = false;
            this.setUserCurrentCompanyX(response.data.current_company);
            this.clientData = this.client();
            this.$toast.success(response.data.message, {
              position: "bottom"
            });
          }).catch(error => {
            this.detailsUpdating = false;
            this.$toast.error(error.response.data.message, {
              position: "bottom"
            });
          });
        }
      },
      updateDetails() {
        if(!this.detailsUpdating) {
          this.detailsUpdating = true;
          clientRequests.updateDetails(this.clientData)
          .then((response) => {
            this.detailsUpdating = false;
            this.setUserCurrentCompanyX(response.data.current_company);
            this.clientData = this.client();
            this.$toast.success(response.data.message, {
              position: "bottom"
            });
          }).catch(error => {
            this.detailsUpdating = false;
            this.$toast.error(error.response.data.message, {
              position: "bottom"
            });
          });
        }
      },
      clientNameEditToggle() {
        this.editingClientName = !this.editingClientName;

        if(this.editingClientName) {
          this.clientNewName = this.client().name;
          this.$nextTick(function() {
            this.$refs.clientNewNameInput.focus();
          });
        } else {
          this.clientNewName = null;
        }
      },
      updateClientName() {
        if(!this.detailsUpdating) {
          this.detailsUpdating = true;
          clientRequests.updateClientName(this.clientNewName, this.client().id)
          .then((response) => {
            this.setUserCurrentCompanyX(response.data.current_company);
            this.clientData = this.client();
            this.detailsUpdating = false;
            this.editingClientName = false;
            this.$toast.success(response.data.message, {
              position: "bottom"
            });
          }).catch(error => {
            console.log(error);
            this.$toast.error(error.response.data.message, {
              position: "bottom"
            });
            this.detailsUpdating = false;
          });
        }
      },
      client() {
        const clientUUID = this.clientUUID;
        return lodash.find(this.getUserCurrentCompanyX.clients, function(c) {
          return c.uuid == clientUUID;
        });
      },
    },
    computed: {
      ...mapGetters([
        'getUserCurrentCompanyX',
        'getCurrenciesListX'
      ]),
    },
  }
</script>