<template>

  <div class="flex flex-col">
    <div class="text-il-dark font-bold uppercase text-xs mt-4">
      invoice title
    </div>

    <input
      class="text-lg outline-none border border-gray-400 focus:border-il-purple px-2 py-1 transition-all duration-200 ease-in-out"
      type="text"
      :value="title"
      @input="updateValue('title', $event.target.value)"
    />

    <div class="text-il-dark font-bold uppercase text-xs mt-4">
      invoice date label
    </div>

    <input
      class="text-lg outline-none border border-gray-400 focus:border-il-purple px-2 py-1 transition-all duration-200 ease-in-out"
      type="text"
      :value="invoiceDateLabel"
      @input="updateValue('invoiceDateLabel', $event.target.value)"
    />

    <div class="text-il-dark font-bold uppercase text-xs mt-4">
      payment date label
    </div>

    <input
      class="text-lg outline-none border border-gray-400 focus:border-il-purple px-2 py-1 transition-all duration-200 ease-in-out"
      type="text"
      :value="paymentDateLabel"
      @input="updateValue('paymentDateLabel', $event.target.value)"
    />

    <div class="text-il-dark font-bold uppercase text-xs mt-4">
      payment period
    </div>

    <div class="flex w-full items-center">
      <div class="flex-grow">
        <input
          class="w-full text-lg outline-none border border-gray-400 focus:border-il-purple px-2 py-1 transition-all duration-200 ease-in-out"
          type="number"
          :value="paymentPeriod"
          @input="updateValue('paymentPeriod', $event.target.value)"
        />
      </div>
      <div
        v-tippy="{ content: 'Default number of days between invoice date and payment date.' }"
        class="flex-shrink border border-il-dark p-1 px-2 text-lg bg-il-dark text-white font-bold"
      >
        ?
      </div>
    </div>

    <div class="text-il-dark font-bold uppercase text-xs mt-4">
      payment terms label
    </div>

    <input
      class="text-lg outline-none border border-gray-400 focus:border-il-purple px-2 py-1 transition-all duration-200 ease-in-out"
      type="text"
      :value="paymentTermsLabel"
      @input="updateValue('paymentTermsLabel', $event.target.value)"
    />

    <div class="text-il-dark font-bold uppercase text-xs mt-4">
      payment terms
    </div>
    <input
      class="text-lg outline-none border border-gray-400 focus:border-il-purple px-2 py-1 transition-all duration-200 ease-in-out"
      type="text"
      :value="paymentTerms"
      @input="updateValue('paymentTerms', $event.target.value)"
    />

    <div class="text-il-dark font-bold uppercase text-xs mt-4">
      balance due
    </div>

    <input
      class="text-lg outline-none border border-gray-400 focus:border-il-purple px-2 py-1 transition-all duration-200 ease-in-out"
      type="text"
      :value="balanceDueLabel"
      @input="updateValue('balanceDueLabel', $event.target.value)"
    />

  </div>

</template>


<script>
  import { mapGetters, mapMutations } from 'vuex';
  import { directive } from 'vue-tippy';

  export default {
    props: [
      'title',
      'invoiceDateLabel',
      'paymentDateLabel',
      'paymentTermsLabel',
      'paymentTerms',
      'paymentPeriod',
      'balanceDueLabel',
      'currencyLabel',
    ],
    emits: [
      'update:title',
      'update:invoiceDateLabel',
      'update:paymentDateLabel',
      'update:paymentTermsLabel',
      'update:paymentTerms',
      'update:paymentPeriod',
      'update:balanceDueLabel',
      'update:currencyLabel',
    ],
    directives: {
      'v-tippy': directive,
    },
    components: {

    },
    data() {
      return {

      }
    },
    methods: {
      ...mapMutations([

      ]),
      updateValue(prop,value) {
        this.$emit('update:'+prop, value);
      },
    },
    computed: {
      ...mapGetters([

      ])
    }
  }
</script>