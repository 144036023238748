<template>

  <div class="flex flex-col">
    <div class="text-il-dark font-bold uppercase text-xs mt-4">
      notes label
    </div>
    <input
      class="text-lg outline-none border border-gray-400 focus:border-il-purple px-2 py-1 transition-all duration-200 ease-in-out"
      type="text"
      :value="notesLabel"
      @input="updateValue('notesLabel', $event.target.value)"
    />
    <div class="text-il-dark font-bold uppercase text-xs mt-4">
      notes text
    </div>
    <textarea
      ref="notesRef"
      class="text-lg outline-none border border-gray-400 focus:border-il-purple px-2 py-1 transition-all duration-200 ease-in-out"
      rows='1'
      data-min-rows='1'
      data-height-step='28'
      :value="notesText"
      @input="updateTextArea('notesText', $event.target, 28)"
      placeholder="Additional notes, contract information, etc."
    >
    </textarea>

    <div class="text-il-dark font-bold uppercase text-xs mt-4">
      terms label
    </div>
    <input
      class="text-lg outline-none border border-gray-400 focus:border-il-purple px-2 py-1 transition-all duration-200 ease-in-out"
      type="text"
      :value="termsLabel"
      @input="updateValue('termsLabel', $event.target.value)"
    />
    <div class="text-il-dark font-bold uppercase text-xs mt-4">
      terms text
    </div>
    <textarea
      ref="termsRef"
      class="text-lg outline-none border border-gray-400 focus:border-il-purple px-2 py-1 transition-all duration-200 ease-in-out"
      rows='1'
      data-min-rows='1'
      data-height-step='28'
      :value="termsText"
      @input="updateTextArea('termsText', $event.target, 28)"
      placeholder="Payment details, detailed payment instructions, etc."
    >
    </textarea>
  </div>

</template>


<script>
  import { mapGetters, mapMutations } from 'vuex';

  export default {
    props: [
      'notesLabel',
      'notesText',
      'termsLabel',
      'termsText'
    ],
    emits: [
      'update:notesLabel',
      'update:notesText',
      'update:termsLabel',
      'update:termsText'
    ],
    mounted() {
      this.$nextTick(function() {
        this.autoResizeMX(this.$refs.notesRef,28);
        this.autoResizeMX(this.$refs.termsRef,28);
      });
    },
    data() {
      return {

      }
    },
    methods: {
      ...mapMutations([

      ]),
      updateValue(prop,value) {
        this.$emit('update:'+prop, value);
      },
      updateTextArea(prop,target,heightStep=null) {
        if(heightStep) {
          this.autoResizeMX(target,heightStep);
        }
        this.$emit('update:'+prop,target.value);
      },
    },
    computed: {
      ...mapGetters([

      ]),
    }
  }
</script>

<style>

</style>