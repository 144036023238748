<template>

  <div class="flex flex-col">
    <div class="text-il-dark font-bold uppercase text-xs mt-4">
      ship to label
    </div>
    <textarea
      ref="labelRef"
      class="text-lg outline-none border border-gray-400 focus:border-il-purple px-2 py-1 transition-all duration-200 ease-in-out"
      rows='1'
      data-min-rows='1'
      data-height-step='28'
      :value="shipToLabel"
      @input="updateValue('shipToLabel', $event.target, 28)"
    >
    </textarea>

    <div class="text-il-dark font-bold uppercase text-xs mt-4">
      ship to name
    </div>
    <textarea
      ref="nameRef"
      class="text-lg outline-none border border-gray-400 focus:border-il-purple px-2 py-1 transition-all duration-200 ease-in-out"
      rows='1'
      data-min-rows='1'
      data-height-step='28'
      :value="shipToName"
      @input="updateValue('shipToName', $event.target, 28)"
    >
    </textarea>

    <div class="text-il-dark font-bold uppercase text-xs mt-4">
      ship to address
    </div>
    <textarea
      ref="addressRef"
      class="text-lg outline-none border border-gray-400 focus:border-il-purple px-2 py-1 transition-all duration-200 ease-in-out"
      rows='1'
      data-min-rows='1'
      data-height-step='28'
      :value="shipToAddress"
      @input="updateValue('shipToAddress', $event.target, 28)"
    >
    </textarea>
  </div>

</template>


<script>
  import { mapGetters, mapMutations } from 'vuex';

  export default {
    props: [
      'shipToLabel',
      'shipToName',
      'shipToAddress'
    ],
    emits: [
      'update:shipToLabel',
      'update:shipToName',
      'update:shipToAddress'
    ],
    mounted() {
      this.$nextTick(function() {
        this.autoResizeMX(this.$refs.labelRef,28);
        this.autoResizeMX(this.$refs.nameRef,28);
        this.autoResizeMX(this.$refs.addressRef,28);
      });
    },
    data() {
      return {

      }
    },
    methods: {
      ...mapMutations([

      ]),
      updateValue(prop,target,heightStep) {
        this.autoResizeMX(target,heightStep);
        this.$emit('update:'+prop,target.value);
      }
    },
    computed: {
      ...mapGetters([

      ]),
    }
  }
</script>