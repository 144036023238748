<template>

  <div class="flex flex-col">


    <div class="text-il-dark font-bold uppercase text-xs mt-4">
      Accent Color
    </div>
    <div class="flex border border-gray-400 p-2">
      <div
        v-for="(color,index) in getAccentColorsX"
        :key="index"
        class=""
      >
        <div
          class="w-8 h-8 mx-1 rounded-lg relative cursor-pointer transition-all duration-200 ease-in-out border border-black"
          :style="{ backgroundColor: color }"
          @click="updateValue('accentColor',color)"
        >
          <div
            v-if="color == accentColor"
            class="absolute top-0.5 right-0.5 text-xs border border-black rounded-full w-2 h-2 bg-white"
          ></div>
        </div>
      </div>

      <div>
        <div
          class="w-8 h-8 mx-1 rounded-lg relative cursor-pointer transition-all duration-200 ease-in-out border border-black"
          :class="isCustomAccentColor ? '' : 'rainbow-bg'"
          :style="{ backgroundColor: accentColor }"
          @click="swatchShow()"
        >
          <div
            v-if="isCustomAccentColor"
            class="absolute top-0.5 right-0.5 text-xs border border-black rounded-full w-2 h-2 bg-white"
          ></div>
        </div>
        <input
          ref="colorPickerRef"
          class="invisible w-0 h-0 absolute"
          type="color"
          :value="accentColor"
          @input="pickColor($event.target, 'accentColor')"
        >
      </div>
    </div>




    <div class="text-il-dark font-bold uppercase text-xs mt-4">
      Accent Text Color
    </div>
    <div class="flex border border-gray-400 p-2">
      <div
        v-for="(color,index) in getAccentColorsX"
        :key="index"
        class=""
      >
        <div
          class="w-8 h-8 mx-1 rounded-lg relative cursor-pointer transition-all duration-200 ease-in-out border border-black"
          :style="{ backgroundColor: color }"
          @click="updateValue('accentTextColor',color)"
        >
          <div
            v-if="color == accentTextColor"
            class="absolute top-0.5 right-0.5 text-xs border border-black rounded-full w-2 h-2 bg-white"
          ></div>
        </div>
      </div>

      <div>
        <div
          class="w-8 h-8 mx-1 rounded-lg relative cursor-pointer transition-all duration-200 ease-in-out border border-black"
          :class="isCustomAccentTextColor ? '' : 'rainbow-bg'"
          :style="{ backgroundColor: accentTextColor }"
          @click="swatchTextShow()"
        >
          <div
            v-if="isCustomAccentTextColor"
            class="absolute top-0.5 right-0.5 text-xs border border-black rounded-full w-2 h-2 bg-white"
          ></div>
        </div>
        <input
          ref="colorPickerTextRef"
          class="invisible w-0 h-0 absolute"
          type="color"
          :value="accentTextColor"
          @input="pickColor($event.target, 'accentTextColor')"
        >
      </div>
    </div>

    <div class="text-il-dark font-bold uppercase text-xs mt-4">
      Currency
    </div>

    <div class="relative">

      <div
        v-if="!listVisible"
        @click="toggleListVisibility"
        class="p-1 border border-gray-400 outline-none text-center bg-white cursor-pointer"
      >
        {{ currency.currency }}
      </div>
      <input
        v-else
        class="w-full p-1 border border-gray-400 outline-none text-center uppercase"
        type="text"
        ref="searchQueryInput"
        v-model="searchQuery"
        placeholder="Search"
      />

      <div
        class="bg-white overflow-y-scroll overflow-x-hidden relative transition-all duration-200 ease-in-out border border-white"
        :class="listVisible ? 'h-150px border-gray-400' : 'h-0'"
      >
        <div
          class="border-b border-gray-400 flex items-center p-1 hover:bg-il-purple-faded transition-all duration-200 ease-in-out cursor-pointer"
          @click="chooseCurrency(currency)"
          v-for="currency in filteredCurrencies"
          :key="currency.id"
        >
          <div class="bg-il-purple w-14 border p-2 text-center text-white">
            {{ currency.symbol }}
          </div>
          <div class="flex flex-col pl-2 text-black">
            <div class="text-sm">
              {{ currency.currency }}
            </div>
            <div class="text-xs text-gray-500">
              {{ currency.country }}
            </div>
          </div>
        </div>
      </div>

      <div
        @click="toggleListVisibility"
        class="absolute p-1 right-1 top-0 text-il-purple cursor-pointer"
      >
        <span v-if="listVisible">
          <i class="fas fa-times-circle"></i>
        </span>
        <span v-else>
          <i class="fas fa-chevron-circle-down"></i>
        </span>

      </div>
    </div>


  </div>

</template>

<script>
  import quickInvoiceRequests from '../../helpers/quickInvoiceRequests';
  import { mapGetters, mapMutations } from 'vuex';
  import lodash from 'lodash';

  export default {
    props: ['accentColor','accentTextColor','currencyId'],
    emits: ['update:accentColor','update:accentTextColor','update:currencyId'],
    data() {
      return {
        listVisible: false,
        searchQuery: '',
        invoiceDataLoading: false,
        invoiceUUID: null
      }
    },
    mounted() {

    },
    components: {

    },
    methods: {
      ...mapMutations([

      ]),
      loadInvoiceData() {
        if(!this.invoiceDataLoading) {
          this.invoiceDataLoading = true;
          quickInvoiceRequests.getInvoiceDetails(this.invoiceUUID)
          .then((response) => {
            this.$emit('loadData', response.data.invoice_details);
            this.invoiceDataLoading = false;
            this.$toast.success(response.data.message, {
              position: "bottom"
            });
          }).catch(error => {
            if(error.response.data.status == 'deleted') {
              this.$toast.error(error.response.data.message, {
                position: "bottom"
              });
            }
            this.invoiceDataLoading = false;
          });
        }

      },
      toggleListVisibility() {
        this.listVisible = !this.listVisible;
        this.$nextTick(function() {
          if(this.listVisible) {
            this.$refs.searchQueryInput.focus();
          }
        });
      },
      chooseCurrency(currency) {
        this.updateValue('currencyId',currency.id);
        this.toggleListVisibility();
        this.searchQuery = '';
      },
      updateValue(prop,value) {
        this.$emit('update:'+prop, value);
      },
      swatchShow() {
        this.$refs.colorPickerRef.click();
      },
      swatchTextShow() {
        this.$refs.colorPickerTextRef.click();
      },
      pickColor(event, prop) {
        this.$emit('update:'+prop, event.value);
      }
    },
    computed: {
      ...mapGetters([
        'getAccentColorsX',
        'getCurrenciesListX'
      ]),
      filteredCurrencies() {
        var query = this.searchQuery;

        if(query == '') {
          return this.getCurrenciesListX;
        }
        return lodash.filter(this.getCurrenciesListX, function(c) {
          return (c.currency.toLowerCase().includes(query.toLowerCase())  || c.country.toLowerCase().includes(query.toLowerCase()) || c.code.toLowerCase().includes(query.toLowerCase()));
        });
      },
      currency() {
        const currencyId = this.currencyId;
        return lodash.find(this.getCurrenciesListX, function(c) { return c.id == currencyId; });
      },
      isCustomAccentColor() {
        return !this.getAccentColorsX.includes(this.accentColor);
      },
      isCustomAccentTextColor() {
        return !this.getAccentColorsX.includes(this.accentTextColor);
      }
    }
  }
</script>

<style scoped>
  .rainbow-bg {
    background-image: linear-gradient(to left, violet, indigo, blue, green, yellow, orange, red);
  }
</style>